interface ApiErrorResponse {
  message: string;
  statusCode: number;
  error: string;
}

export const parseApiError = (errorMessage: string): string => {
  try {
    // Try to extract JSON from the error message
    const jsonMatch = errorMessage.match(/Body\s*(\{.*\})/);
    if (jsonMatch) {
      const errorResponse: ApiErrorResponse = JSON.parse(jsonMatch[1]);

      // Special handling for contact already exists error
      if (
        errorResponse.statusCode === 409 &&
        errorResponse.message.includes("Contact already exists")
      ) {
        const contactId = errorResponse.message.match(/ID: (\d+)/)?.[1];
        return contactId
          ? `Contact already exists (ID: ${contactId})`
          : "Contact already exists";
      }

      return errorResponse.message;
    }

    // If no JSON found, return the original message
    return errorMessage.replace("API error occurred: ", "");
  } catch (e) {
    // If parsing fails, return a cleaned version of the original message
    return errorMessage.replace("API error occurred: ", "");
  }
};
