import { useOrganization } from "@clerk/nextjs";
import { useState } from "react";

export const useInviteOrganizationMember = (onInviteSuccess: () => void) => {
  const { organization } = useOrganization();
  const [inviteEmail, setInviteEmail] = useState("");
  const [isInviting, setIsInviting] = useState(false);

  const handleInviteUser = async () => {
    if (!organization || !inviteEmail) return;
    setIsInviting(true);
    try {
      await organization.inviteMember({
        emailAddress: inviteEmail,
        role: "org:member",
      });
      setInviteEmail("");
      onInviteSuccess();
    } catch (error) {
      console.error("Error inviting user:", error);
    } finally {
      setIsInviting(false);
    }
  };

  return { inviteEmail, setInviteEmail, handleInviteUser, isInviting };
};
