import { DashboardReport, DateRange } from "@/app/types";

export const fetchDashboardReport = async (
  timeframe: DateRange
): Promise<DashboardReport> => {
  const params = new URLSearchParams({
    timeframe,
  });

  const response = await fetch(`/api/dashboard/report?${params.toString()}`);

  if (!response.ok) {
    throw new Error("Failed to fetch dashboard report");
  }

  const data: DashboardReport = await response.json();
  return data;
};
