import { useUser } from "@clerk/nextjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CrmContact,
  CrmTelephone,
} from "@unified-api/typescript-sdk/sdk/models/shared";
import toast from "react-hot-toast";
import { editLeadMigrationStatus, fetchCrmConnection } from "../api/crm/client";
import { createCRMContact } from "../api/providers/unified";
import { CrmProvider } from "../types/crm";
import { LeadWithProspect } from "../types/db";
import { parseApiError } from "../utils/error-utils";

export type CrmConnectionStatus = "loading" | "connected" | "disconnected";

interface MigrateLeadParams {
  lead: LeadWithProspect;
  provider: CrmProvider;
}

interface MigrateMultipleLeadsParams {
  leads: LeadWithProspect[];
  provider: CrmProvider;
}

export const useCrmMigration = () => {
  const queryClient = useQueryClient();
  const { isLoaded: isUserLoaded } = useUser();

  // Query for CRM connection
  const {
    data: connection,
    isLoading,
    error: connectionError,
  } = useQuery({
    queryKey: ["crmConnection"],
    queryFn: fetchCrmConnection,
    enabled: isUserLoaded,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  // Get available providers from the connection
  const availableProviders = connection?.crm
    ? (Object.keys(connection.crm) as CrmProvider[])
    : [];

  // Mutation for migrateing a single lead
  const migrateLeadMutation = useMutation({
    mutationFn: async ({ lead, provider }: MigrateLeadParams) => {
      if (!connection?.crm?.[provider]) {
        throw new Error(`No ${provider} connection found`);
      }

      const crmLead: CrmContact = {
        name: lead.prospect?.full_name || "",
        emails: [
          {
            email: lead.lead.primary_email,
            type: "WORK",
          },
        ],
        telephones:
          lead.lead.personal_phone_numbers.length >= 1
            ? lead.lead.personal_phone_numbers.map(
                (phone) =>
                  ({
                    telephone: phone,
                    type: "WORK",
                  }) as CrmTelephone
              )
            : undefined,
        company: lead.prospect?.company?.name || "",
      };

      const response = await createCRMContact(
        connection.crm[provider],
        crmLead
      );

      await editLeadMigrationStatus({
        leadId: lead.lead._id.toString(),
        connectionId: connection.crm[provider],
        provider,
        crmLeadId: response.id as string,
      });

      return response;
    },
    onError: (error) => {
      toast.error(
        error instanceof Error
          ? parseApiError(error.message)
          : "Failed to migrate lead"
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["leads", variables.lead.lead._id.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: ["leadList"],
      });
      toast.success(
        `Lead successfully migrated to ${variables.provider.toUpperCase()}`
      );
    },
  });

  // Mutation for syncing multiple leads
  const migrateMultipleLeadsMutation = useMutation({
    mutationFn: async ({ leads, provider }: MigrateMultipleLeadsParams) => {
      if (!connection?.crm?.[provider]) {
        throw new Error(`No ${provider} connection found`);
      }

      const results = [];
      const toastId = toast.loading(
        `Migrating ${leads.length} leads to ${provider.toUpperCase()}...`
      );

      try {
        for (const lead of leads) {
          try {
            const result = await migrateLeadMutation.mutateAsync({
              lead,
              provider,
            });
            results.push({ lead, result, success: true });
            // Update loading toast with progress
            toast.loading(
              `Migrated ${results.length} of ${leads.length} leads...`,
              { id: toastId }
            );
          } catch (err) {
            results.push({
              lead,
              error:
                err instanceof Error
                  ? parseApiError(err.message)
                  : "Failed to migrate lead",
              success: false,
            });
          }
        }

        const successCount = results.filter((r) => r.success).length;

        if (successCount === leads.length) {
          toast.success(`Successfully migrated all ${leads.length} leads`, {
            id: toastId,
          });
        } else {
          toast.success(
            `Migrated ${successCount} out of ${leads.length} leads`,
            {
              id: toastId,
            }
          );
        }

        return results;
      } catch (error) {
        toast.error(
          error instanceof Error
            ? parseApiError(error.message)
            : "Failed to sync leads",
          { id: toastId }
        );
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["leadList"],
      });
    },
  });

  return {
    migrateLeadToCrm: migrateLeadMutation.mutateAsync,
    migrateMultipleLeads: migrateMultipleLeadsMutation.mutateAsync,

    isMigrating:
      migrateLeadMutation.isPending || migrateMultipleLeadsMutation.isPending,
    isLoading,
    error:
      connectionError ||
      migrateLeadMutation.error ||
      migrateMultipleLeadsMutation.error,

    hasConnection: availableProviders.length > 0,
    connectionStatus: isLoading
      ? "loading"
      : availableProviders.length > 0
        ? "connected"
        : "disconnected",
    availableProviders,

    migrateLeadState: migrateLeadMutation,
    migrateMultipleLeadsState: migrateMultipleLeadsMutation,
  };
};
