import { useUser } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm, UseFormRegisterReturn } from "react-hook-form";
import * as yup from "yup";

const profilePreferencesForm = yup
  .object({
    email: yup
      .string()
      .email("Wrong email format.")
      .required("Email is required."),
    oldPassword: yup.string().when("password", {
      is: (password: string | undefined) => password && password.length >= 1,
      then: (schema) =>
        schema.required(
          "Old password is required when creating a new password."
        ),
      otherwise: (schema) => schema,
    }),
    password: yup
      .string()
      .test("len", "Password must be at least 8 characters.", (val) => {
        if (val === undefined || val === null || val === "") return true; // Pass if not provided
        return val.length >= 8; // Must be at least 8 chars if provided
      }),
    fullName: yup.string().required("Full name is required."),
    phoneNumber: yup.string(),
    emailNotifications: yup.boolean(),
  })
  .required();

export type ProfilePreferencesFormValues = yup.InferType<
  typeof profilePreferencesForm
>;

export interface InputConfig {
  name: keyof ProfilePreferencesFormValues;
  type: "text" | "email" | "password" | "tel";
  placeholder: string;
  info?: string;
  register: UseFormRegisterReturn;
}

export const useProfilePreferencesForm = () => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [originalProfileDetails, setOriginalProfileDetails] = useState<
    Partial<ProfilePreferencesFormValues>
  >({});

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfilePreferencesFormValues>({
    resolver: yupResolver(profilePreferencesForm),
    defaultValues: {
      email: "",
      password: "",
      fullName: "",
      phoneNumber: "",
      emailNotifications: true,
    },
  });

  const registerEmail = register("email");
  const registerOldPassword = register("oldPassword");
  const registerPassword = register("password");
  const registerFullName = register("fullName");
  const registerPhoneNumber = register("phoneNumber");
  const registerEmailNotifications = register("emailNotifications");

  const onFormSubmit: SubmitHandler<ProfilePreferencesFormValues> = async (
    payload
  ) => {
    const cleanedData = _.omitBy(
      payload,
      (value) => value === "" || value === null || value === undefined
    );

    // Compare with original data and only keep changed fields
    const changedProfileDetails = _.omitBy(cleanedData, (value, key) =>
      _.isEqual(
        value,
        originalProfileDetails[key as keyof ProfilePreferencesFormValues]
      )
    );

    console.log("Changed details:", changedProfileDetails);

    try {
      if (user) {
        // Update basic user information
        if (changedProfileDetails.fullName) {
          const [firstName, ...lastNameParts] = (
            changedProfileDetails.fullName as string
          ).split(" ");
          const lastName = lastNameParts.join(" ");
          await user.update({
            firstName,
            lastName,
          });
        }

        if (changedProfileDetails.phoneNumber) {
          await user.update({
            unsafeMetadata: {
              ...user.unsafeMetadata,
              phoneNumber: changedProfileDetails.phoneNumber,
            },
          });
        }

        if ("emailNotifications" in changedProfileDetails) {
          await user.update({
            unsafeMetadata: {
              ...user.unsafeMetadata,
              emailNotifications: !changedProfileDetails.emailNotifications, // Invert before saving
            },
          });
        }

        if (
          changedProfileDetails.oldPassword &&
          changedProfileDetails.password
        ) {
          await user.updatePassword({
            currentPassword: changedProfileDetails.oldPassword as string,
            newPassword: changedProfileDetails.password as string,
          });
        }
      }

      console.log("Profile edited successfully");
    } catch (error) {
      console.error("Error editing profile:", error);
    }
  };

  useEffect(() => {
    if (user) {
      console.log("User unsafe metadata:", user.unsafeMetadata);
      const userData = {
        email: user.primaryEmailAddress?.emailAddress || "",
        fullName: `${user.firstName} ${user.lastName}` || "",
        phoneNumber: (user.unsafeMetadata.phoneNumber as string) || "",
        emailNotifications: !(
          (user.unsafeMetadata.emailNotifications as boolean) || false
        ), // Invert when setting form value
      };

      setOriginalProfileDetails(userData);

      setValue("email", userData.email);
      setValue("fullName", userData.fullName);
      setValue("phoneNumber", userData.phoneNumber);
      setValue("emailNotifications", userData.emailNotifications);

      setIsLoading(false);
    }
  }, [user, setValue]);

  return {
    control,
    isLoading,
    errors,
    registerEmail,
    registerOldPassword,
    registerPassword,
    registerFullName,
    registerPhoneNumber,
    registerEmailNotifications,
    onFormSubmit,
    handleSubmit,
  };
};
