import {
  createCleaningTask,
  fetchCleaningStatus,
} from "@/app/api/lead-lists/client";
import {
  CleanedField,
  CleaningConfig,
  CleaningDocument,
  CleaningStatus,
} from "@/app/types/cleaning";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { defaultCleaningFields } from "../utils/cleaning-utils";

export const useCleaner = (leadListId: string) => {
  const [selectedFields, setSelectedFields] = useState<Set<string>>(new Set());
  const [strategy, setStrategy] = useState<"conservative" | "aggressive">(
    "conservative"
  );
  const [jobId, setJobId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const cleaningMutation = useMutation({
    mutationFn: async () => {
      const config: CleaningConfig = {
        fields: Array.from(selectedFields).map((fieldId) => ({
          id: fieldId,
          ...defaultCleaningFields[fieldId],
          enabled: true,
        })),
        strategy,
        auto_apply: false,
      };
      return createCleaningTask(leadListId, config);
    },
    onSuccess: (data) => {
      setJobId(data.jobId);
      queryClient.invalidateQueries({
        queryKey: ["cleaningStatus", leadListId],
      });
    },
  });

  const cleaningStatus = useQuery<
    CleaningStatus & { results: CleaningDocument[] }
  >({
    queryKey: ["cleaningStatus", leadListId, jobId],
    queryFn: () => fetchCleaningStatus(leadListId, jobId!),
    enabled: !!jobId,
    refetchInterval: (response) => {
      return response?.state.data?.status === "completed" ||
        response?.state.data?.status === "failed"
        ? false
        : 1000;
    },
  });

  const startCleaning = async () => {
    if (selectedFields.size === 0) return;
    await cleaningMutation.mutateAsync();
  };

  const toggleField = (fieldId: string) => {
    const newFields = new Set(selectedFields);
    if (newFields.has(fieldId)) {
      newFields.delete(fieldId);
    } else {
      newFields.add(fieldId);
    }
    setSelectedFields(newFields);
  };

  const resetForm = () => {
    setSelectedFields(new Set());
    setStrategy("conservative");
    setJobId(null);
  };

  // Log raw data for debugging
  console.log("Raw cleaning status data:", cleaningStatus.data);

  const status: CleaningStatus = cleaningStatus.data
    ? {
        status: cleaningStatus.data.status,
        progress: cleaningStatus.data.progress || 0,
        current_field: cleaningStatus.data.current_field,
        completed_fields: cleaningStatus.data.completed_fields || [],
        start_time: cleaningStatus.data.start_time,
        end_time: cleaningStatus.data.end_time,
      }
    : {
        status: "idle",
        progress: 0,
        completed_fields: [],
      };

  // Transform and group results by field
  const results = useMemo(() => {
    console.log("cleaningStatus.data", cleaningStatus.data?.results);
    if (!cleaningStatus.data?.results) return [];

    // Group results by field_id and select the one with the highest confidence
    const groupedResults = cleaningStatus.data.results.reduce<
      Record<string, any>
    >((acc, result) => {
      result.cleaned_fields.forEach((cleanedField: CleanedField) => {
        const fieldId = cleanedField.field_id;

        if (
          !acc[fieldId] ||
          acc[fieldId].confidence < cleanedField.confidence
        ) {
          acc[fieldId] = {
            original_value: cleanedField.original_value,
            cleaned_value: cleanedField.cleaned_value,
            confidence: cleanedField.confidence,
            applied: cleanedField.applied || false,
          };
        }
      });

      return acc;
    }, {});

    // Convert grouped results to array format
    return Object.entries(groupedResults).map(([fieldId, change]) => ({
      field_id: fieldId,
      change: change,
    }));
  }, [cleaningStatus.data?.results]);

  return {
    selectedFields,
    strategy,
    status,
    results,
    toggleField,
    setStrategy,
    startCleaning,
    resetForm,
    isStarting: cleaningMutation.isPending,
    error: cleaningMutation.error || cleaningStatus.error,
  };
};
