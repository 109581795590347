import { CleaningField, Lead, Prospect } from "../types";

export const defaultCleaningFields: Record<
  string,
  Omit<CleaningField, "id" | "enabled">
> = {
  fullName: {
    label: "Full Name",
    description: "Standardize name formatting, fix capitalization",
    type: "name",
  },
  companyName: {
    label: "Company Name",
    description: "Clean company names, remove legal entities (Inc., LLC)",
    type: "company",
  },
  jobTitle: {
    label: "Occupation",
    description: "Standardize titles, normalize seniority levels",
    type: "title",
  },
  location: {
    label: "Location",
    description: "Format locations consistently, standardize regions",
    type: "location",
  },
  emailAddress: {
    label: "Email Address",
    description: "Validate format, fix common domain typos",
    type: "email",
  },
  phoneNumber: {
    label: "Phone Number",
    description: "Standardize format, validate country codes",
    type: "phone",
  },
  headline: {
    label: "Headline",
    description: "Clean professional headlines, remove special characters",
    type: "headline",
  },
};

export const retrieveFieldValue = (
  field: CleaningField,
  lead: Lead,
  prospect: Prospect | null
): string => {
  if (!prospect) return "";

  switch (field.type) {
    case "name":
      return prospect.full_name || "";

    case "company":
      return prospect.experiences?.[0]?.company || "";

    case "title":
      return prospect.experiences?.[0]?.title || "";

    case "location":
      return [prospect.city, prospect.state, prospect.country]
        .filter(Boolean)
        .join(", ");

    case "email":
      if (field.id === "primary_email") {
        return lead.primary_email || "";
      } else if (field.id === "other_emails") {
        return lead.other_emails?.join(", ") || "";
      } else if (field.id === "personal_emails") {
        return lead.personal_emails?.join(", ") || "";
      }
      return lead.primary_email || "";

    case "phone":
      return lead.personal_phone_numbers?.[0] || "";

    case "headline":
      return prospect.headline || "";

    default:
      console.warn(`Unhandled field type: ${field.type}`);
      return "";
  }
};
