import { useOrganization } from "@clerk/nextjs";
import { OrganizationMembershipResource } from "@clerk/types";
import { useCallback, useEffect, useState } from "react";

export const useOrganizationMembers = () => {
  const { organization, isLoaded } = useOrganization();
  const [members, setMembers] = useState<OrganizationMembershipResource[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMembers = useCallback(async () => {
    if (!organization) return;
    // setIsLoading(true);
    try {
      const membersList = await organization.getMemberships();
      console.log("membersList", membersList);
      setMembers(membersList.data);
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      // setIsLoading(false);
    }
  }, [organization]);

  useEffect(() => {
    if (isLoaded) {
      fetchMembers();
    }
  }, [isLoaded, fetchMembers]);

  const handleRoleChange = async (
    memberId: string,
    newRole: "org:admin" | "org:member"
  ) => {
    if (!organization) return;
    try {
      await organization.updateMember({ userId: memberId, role: newRole });
      await fetchMembers();
    } catch (error) {
      console.error("Error updating member role:", error);
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!organization) return;
    try {
      await organization.removeMember(memberId);
      await fetchMembers();
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  return {
    members,
    isLoading,
    handleRoleChange,
    handleRemoveMember,
    refetchMembers: fetchMembers,
  };
};
