import { CrmConnection, CRMOption, CrmProvider } from "@/app/types";

interface EditLeadSyncStatusParams {
  leadId: string;
  connectionId: string;
  provider: CrmProvider;
  crmLeadId: string;
}

export const fetchCRMOptions = async () => {
  const response = await fetch("/api/crm/options");
  const data: CRMOption[] = await response.json();
  return data;
};

export const fetchCrmConnection = async (): Promise<CrmConnection> => {
  const response = await fetch("/api/crm/connection");
  if (!response.ok) {
    if (response.status === 404) {
      return { crm: {} };
    }
    throw new Error(`Failed to fetch connection: ${response.statusText}`);
  }
  return response.json();
};

export const editLeadMigrationStatus = async ({
  leadId,
  connectionId,
  provider,
  crmLeadId,
}: EditLeadSyncStatusParams) => {
  const response = await fetch(`/api/leads/${leadId}/migration-status`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      synced: true,
      synced_at: new Date(),
      connection_id: connectionId,
      provider,
      crm_lead_id: crmLeadId,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to edit lead migration status");
  }

  return response.json();
};
