import { useOrganization, useUser } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

const companyPreferencesForm = yup
  .object({
    companyName: yup.string().required("Company name is required"),
    contactEmail: yup
      .string()
      .email("Invalid email")
      .required("Contact email is required"),
    enableSSO: yup.boolean(),
  })
  .required();

export type OrganizationPreferencesFormValues = yup.InferType<
  typeof companyPreferencesForm
>;

export const useOrganizationPreferencesForm = () => {
  const { user, isLoaded: isUserLoaded } = useUser();
  const { organization, isLoaded: isOrganizationLoaded } = useOrganization();
  const [isLoading, setIsLoading] = useState(true);
  const [originalCompanyDetails, setOriginalCompanyDetails] =
    useState<OrganizationPreferencesFormValues>({
      companyName: "",
      contactEmail: "",
      enableSSO: false,
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<OrganizationPreferencesFormValues>({
    resolver: yupResolver(companyPreferencesForm),
    defaultValues: {
      companyName: "",
      contactEmail: "",
      enableSSO: false,
    },
  });

  const onSubmit: SubmitHandler<OrganizationPreferencesFormValues> = async (
    payload
  ) => {
    console.log("Form submitted with data:", payload);
    const cleanedData = _.omitBy(
      payload,
      (value) => value === "" || value === null || value === undefined
    );

    // Compare with original data and only keep changed fields
    const changedCompanyDetails = _.omitBy(cleanedData, (value, key) =>
      _.isEqual(
        value,
        originalCompanyDetails[key as keyof OrganizationPreferencesFormValues]
      )
    );

    console.log("Changed details:", changedCompanyDetails);

    try {
      if (user) {
        await user.update({
          unsafeMetadata: {
            ...user.unsafeMetadata,
            contactEmail: changedCompanyDetails.contactEmail,
            enableSSO: changedCompanyDetails.enableSSO,
          },
        });
      }

      console.log(`Organization: ${organization}`, isOrganizationLoaded);
      let activeOrg = organization;

      // If no active organization, try to get the first one from the user's organization memberships
      if (!activeOrg) {
        const memberships = await user?.getOrganizationMemberships();
        if (memberships?.data && memberships.data.length > 0) {
          activeOrg = memberships.data[0].organization;
        }
      }
      if (activeOrg) {
        await activeOrg.update({
          name: changedCompanyDetails.companyName as string,
        });
        await activeOrg.reload();
        console.log("Company has been edited.");
      }
    } catch (error) {
      console.error("Error editing company:", error);
    }
  };

  useEffect(() => {
    const loadOrganizationData = async () => {
      if (user && isUserLoaded && isOrganizationLoaded) {
        console.log("User unsafe metadata:", user.unsafeMetadata);

        let activeOrg = organization;

        // If no active organization, try to get the first one from the user's organization memberships
        if (!activeOrg) {
          const memberships = await user?.getOrganizationMemberships();
          if (memberships?.data && memberships.data.length > 0) {
            activeOrg = memberships.data[0].organization;
          }
        }

        console.log("Organization:", activeOrg);

        const userMetadata = user.unsafeMetadata as {
          contactEmail: string;
          enableSSO: boolean;
        };

        const userData = {
          companyName: activeOrg?.name || "",
          contactEmail:
            userMetadata.contactEmail ??
            user.primaryEmailAddress?.emailAddress ??
            "",
          enableSSO: userMetadata.enableSSO || false,
        };

        setValue("companyName", userData.companyName);
        setValue("contactEmail", userData.contactEmail);
        setValue("enableSSO", userData.enableSSO);

        setOriginalCompanyDetails(
          userData as OrganizationPreferencesFormValues
        );
        setIsLoading(false);
      }
    };

    loadOrganizationData();
  }, [user, organization, isUserLoaded, isOrganizationLoaded, setValue]);

  return {
    register,
    handleSubmit,
    errors,
    isLoading,
    onSubmit,
    watchEnableSSO: watch("enableSSO"),
  };
};
