import { Lead } from "@/app/types";
import { useState } from "react";

export const useLeadListFilters = (initialOwner: string = "") => {
  const [selectedOwnedBy, setSelectedOwnedBy] = useState(initialOwner);
  const [selectedList, setSelectedList] = useState("");
  const [emailStatus, setEmailStatus] = useState<
    Set<Lead["primary_email_status"]>
  >(new Set(["valid"]));
  const [headlineFilter, setHeadlineFilter] = useState("");
  const [companyFilter, setCompanyFilter] = useState("");
  const [dateRange, setDateRange] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");

  return {
    filters: {
      selectedOwnedBy,
      selectedList,
      emailStatus,
      headlineFilter,
      companyFilter,
      dateRange,
      searchQuery,
    },
    setters: {
      setSelectedOwnedBy,
      setSelectedList,
      setEmailStatus,
      setHeadlineFilter,
      setCompanyFilter,
      setDateRange,
      setSearchQuery,
    },
  };
};
