import { LeadListDetails } from "@/app/types";
import { useEffect, useState } from "react";

export const useLeadList = (id: string) => {
  const [leadListData, setLeadListData] = useState<LeadListDetails | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    setError(null);

    const eventSource = new EventSource(`/api/lead-lists/${id}`);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "initial") {
        setLeadListData(data.data);
        setLoading(false);
      } else if (data.type === "update") {
        setLeadListData(data.data);
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();
      setError("An error occurred while receiving updates.");
      setLoading(false);
    };

    return () => {
      eventSource.close();
    };
  }, [id]);

  return { leadListData, loading, error };
};
