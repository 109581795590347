import { LeadListDetails } from "@/app/types";
import { useMemo } from "react";
import { useLeadListFilters } from "./use-lead-list-filters.hook";

export const useFilteredLeads = (
  leadListData: LeadListDetails | null,
  filters: ReturnType<typeof useLeadListFilters>["filters"]
) => {
  return useMemo(() => {
    if (!leadListData?.leads) return [];

    return leadListData.leads.filter((item) => {
      const { lead, prospect } = item;

      const matchesHeadline = filters.headlineFilter
        ? prospect?.headline
            ?.toLowerCase()
            .includes(filters.headlineFilter.toLowerCase())
        : true;

      const matchesCompany = filters.companyFilter
        ? prospect?.company?.name
            ?.toLowerCase()
            .includes(filters.companyFilter.toLowerCase())
        : true;

      const matchesSearch = filters.searchQuery
        ? prospect?.full_name
            ?.toLowerCase()
            .includes(filters.searchQuery.toLowerCase()) ||
          lead.primary_email
            .toLowerCase()
            .includes(filters.searchQuery.toLowerCase()) ||
          prospect?.company?.name
            ?.toLowerCase()
            .includes(filters.searchQuery.toLowerCase())
        : true;

      // Email status filter using the correct type
      const matchesEmailStatus =
        filters.emailStatus.size === 0 ||
        filters.emailStatus.has(lead.primary_email_status);

      // Add date filter based on lead.created_at
      const matchesDate = true; // Implement based on your date range requirements
      if (filters.dateRange !== "all") {
        const leadDate = new Date(lead.created_at);

        switch (filters.dateRange) {
          case "today":
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return leadDate >= today;
          case "yesterday":
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);
            const yesterdayEnd = new Date(yesterday);
            yesterdayEnd.setHours(23, 59, 59, 999);
            return leadDate >= yesterday && leadDate <= yesterdayEnd;
          case "last7days":
            const last7Days = new Date();
            last7Days.setDate(last7Days.getDate() - 7);
            return leadDate >= last7Days;
          case "last30days":
            const last30Days = new Date();
            last30Days.setDate(last30Days.getDate() - 30);
            return leadDate >= last30Days;
        }
      }

      return (
        matchesHeadline &&
        matchesCompany &&
        matchesSearch &&
        matchesEmailStatus &&
        matchesDate
      );
    });
  }, [leadListData?.leads, filters]);
};
