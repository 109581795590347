import { UnifiedTo } from "@unified-api/typescript-sdk";
import { QueryParamCategories } from "@unified-api/typescript-sdk/sdk/models/operations";
import {
  CrmContact,
  CrmLead,
} from "@unified-api/typescript-sdk/sdk/models/shared";

const unifiedClient = new UnifiedTo({
  security: {
    jwt:
      process.env.UNIFIED_API_KEY ??
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTU3YTg0NDZkMDVlZGIzMGQ3M2MzNzAiLCJ3b3Jrc3BhY2VfaWQiOiI2NjAwZjFkOWI5NDA3MjA0NmMyOWU2OTAiLCJpYXQiOjE3MDAyNDM1MjR9.i_xECx62HeWqL98y5WB30rGfPg9EPOUIwpkfteW5pYs",
  },
});

export const fetchCRMConnections = async () => {
  const connections =
    await unifiedClient.integration.listUnifiedIntegrationWorkspaces({
      workspaceId: process.env.UNIFIED_APP_KEY as string,
      categories: [QueryParamCategories.Crm],
    });
  return connections;
};

export const createCRMLead = async (connectionId: string, payload: CrmLead) => {
  const response = await unifiedClient.crm.createCrmLead({
    connectionId: connectionId,
    crmLead: payload,
  });
  return response;
};

export const createCRMContact = async (
  connectionId: string,
  payload: CrmContact
) => {
  const response = await unifiedClient.crm.createCrmContact({
    connectionId: connectionId,
    crmContact: payload,
  });
  return response;
};
