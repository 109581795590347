import { fetchDashboardReport } from "@/app/api/dashboard/report/client";
import { DashboardReport, DateRange } from "@/app/types";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const useDashboard = () => {
  const [dateRange, setDateRange] = useState<DateRange>("Today");

  const {
    data: dashboardReport,
    isLoading,
    error,
  } = useQuery<DashboardReport, Error>({
    queryKey: ["dashboardReport", dateRange],
    queryFn: () => fetchDashboardReport(dateRange),
  });

  return {
    dashboardReport,
    isLoading,
    error,
    dateRange,
    setDateRange,
  };
};
