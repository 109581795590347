import { editLeadList } from "@/app/api/lead-lists/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useEditLeadList = (leadListId: string) => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: editLeadList,
    onSuccess: () => {
      // Invalidate lead list queries to refresh the data
      queryClient.invalidateQueries({ queryKey: ["leadList", leadListId] });
    },
  });

  // Helper function to remove leads
  const removeLeads = async (leadIds: string[]) => {
    return updateMutation.mutateAsync({
      leadListId,
      edits: {
        removedLeadIds: leadIds,
      },
    });
  };

  // Helper function to add leads
  const addLeads = async (leadIds: string[]) => {
    return updateMutation.mutateAsync({
      leadListId,
      edits: {
        addedLeadIds: leadIds,
      },
    });
  };

  // Helper function to update lead list details
  const updateDetails = async (updates: {
    name?: string;
    description?: string;
    pinned?: boolean;
  }) => {
    return updateMutation.mutateAsync({
      leadListId,
      edits: updates,
    });
  };

  return {
    removeLeads,
    addLeads,
    updateDetails,
    isUpdating: updateMutation.isPending,
    error: updateMutation.error,
  };
};
