import { CleaningConfig, LeadList } from "@/app/types";
import { WithId } from "mongodb";
import { CreateLeadListRequest } from "./route";

export const fetchLeadLists = async (): Promise<WithId<LeadList>[]> => {
  const response = await fetch("/api/lead-lists", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch lead lists");
  }
  return response.json();
};

export const fetchLeadList = async (id: string): Promise<LeadList> => {
  const response = await fetch(`/api/lead-lists/${id}`, {
    method: "GET",
  });
  if (!response.ok) {
    throw new Error("Failed to fetch lead list");
  }
  return response.json();
};

export const createLeadList = async (
  payload: CreateLeadListRequest
): Promise<LeadList> => {
  const response = await fetch("/api/lead-lists", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to create lead list");
  }
  return response.json();
};

export const refreshLeadList = async (id: string): Promise<void> => {
  const response = await fetch(`/api/lead-lists/${id}`, {
    method: "POST",
    body: JSON.stringify({ action: "refresh" }),
  });
  if (!response.ok) {
    throw new Error("Failed to refresh lead list");
  }
  return response.json();
};

export const deleteLeadList = async (id: string): Promise<void> => {
  const response = await fetch(`/api/lead-lists/${id}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error("Failed to delete lead list");
  }
  return response.json();
};

export const editLeadList = async ({
  leadListId,
  edits,
}: {
  leadListId: string;
  edits: {
    name?: string;
    description?: string;
    pinned?: boolean;
    removedLeadIds?: string[];
    addedLeadIds?: string[];
  };
}) => {
  const response = await fetch(`/api/lead-lists/${leadListId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(edits),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to update lead list");
  }

  return response.json();
};

export const createCleaningTask = async (
  leadListId: string,
  config: CleaningConfig
): Promise<{ jobId: string }> => {
  const response = await fetch(`/api/lead-lists/${leadListId}/clean`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ config }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to start cleaning");
  }

  return response.json();
};

export const fetchCleaningStatus = async (
  leadListId: string,
  taskId: string
) => {
  const response = await fetch(`/api/lead-lists/${leadListId}/clean/${taskId}`);
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to fetch cleaning status");
  }
  return response.json();
};
