import { ArrowDownCircle, ArrowUpCircle } from "lucide-react";
import { ReactNode } from "react";

export const useMetrics = () => {
  const formatNumber = (num: number): string => {
    return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
  };

  const determineChangeIcon = (
    current: number,
    previous: number
  ): ReactNode => {
    return current > previous ? (
      <ArrowUpCircle size={16} className="text-[#3E8AFF]" />
    ) : (
      <ArrowDownCircle size={16} className="text-[#FF3232]" />
    );
  };

  return {
    formatNumber,
    determineChangeIcon,
  };
};
